import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  View,
  Alert,
  ScrollView,
  SafeAreaView,
  Image,
} from 'react-native';

<link href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap" rel="stylesheet"/>
import {useNavigation} from '@react-navigation/native';
import UserManagementScreen from './BackOfficeScreens/UserManagementScreen';
import CarsManagement from './CarScreens/CarsManagement';
import {Auth} from 'aws-amplify';
import ProfilePopUpComponent from '../BackOffice/UserManagement/ProfilePopUpComponent';
import DashboardScreen from './DashboardScreen';
import NewCarScreen from './CarScreens/NewCarScreen';
import {Button, IconButton, Input, InputGroup, Drawer, List} from 'rsuite';
import {Icon} from '@rsuite/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CustomersScreen from './CustomersScreen';
import CarTagManagerScreen from '../BackOffice/CarTagManagerScreen';
import GlobalParametersScreen from '../BackOffice/GlobalParametersScreen';
import NewCustomerScreen from './RentScreens/CustomerManagement/NewCustomerScreen';
import NewRentAggreementScreen from './RentScreens/RentAgreement/NewRentAggreementScreen';
import RentAgreementManagement from './RentScreens/RentAgreement/RentAgreementManagement';
import RentCustomerManagementScreen from './RentScreens/CustomerManagement/RentCustomerManagementScreen';
import MultipleCarScreen from './CarScreens/MultipleCarScreen';
import FreshCarsManagemnt from './CarScreens/FreshCarManagement';
import MultipleCarUpdateScreen from './CarScreens/MultipleCarUpdate';
import MultipleFileUploadScreen from './CarScreens/MultipleFileUploadScreen';
import BMLogo from '../../assets/BilenMobi.png';
import CarDeliveryDocument from './SalesDepScreens/CarDeliveryDocument';
import BMPScreen from './BackOfficeScreens/BMPScreen';
import KasaScreen from './BackOfficeScreens/KasaScreen';
import {useUserToken} from '../hooks/useUserToken';
import configData from "../config/config.json";
import KaskoValueCalculator from './CarScreens/CarTabScreens/KaskoValueCalculator';
import CarInfoScreen from './CarScreens/CarInfoScreen';
import {useValidationRules} from '../hooks/useUtils';
import bcrLogo from '../../assets/bcrLogo.png'
import sahibinden_icon from '../../assets/LinkIcons/sahibinden_icon.png'
import kolaycar_icon from '../../assets/LinkIcons/kolaycar_icon.png'
import egm_icon from '../../assets/LinkIcons/egm_icon.png'
import logosoft_icon from '../../assets/LinkIcons/logosoft_icon.png'
import gib_icon from '../../assets/LinkIcons/gib_icon.png'
import opet_icon from '../../assets/LinkIcons/opet_icon.png'
import shell_icon from '../../assets/LinkIcons/shell_icon.png'
import arvento_icon from '../../assets/LinkIcons/arvento_icon.png'
import turuncuPro_icon from '../../assets/LinkIcons/turuncuPro_icon.png'
import tsb_icon from '../../assets/LinkIcons/tsb_icon.png'
import turuncupro_icon from '../../assets/LinkIcons/turuncuPro_icon.png'
import hgs_kurumsal_icon from '../../assets/LinkIcons/hgs_kurumsal_icon.png'
import ekap_icon from '../../assets/LinkIcons/ekap_icon.png'
import RentContractTemplate from './RentScreens/RentOffer/RentContractTemplate';
import RentContractOffers from './RentScreens/RentOffer/RentContractOffers';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CustomText from '../Components/CustomText';
import {ActivityIndicator} from 'react-native-web';
import {useMessage} from '../hooks/useMessage';
import SalesVisitorsScreen from './SalesDepScreens/SalesVisitorsScreen';
import Requests from './RequestsScreens/Requests';
import SalesCarsOffers from './SalesDepScreens/SalesCarsOffers';
import SalesCarOfferDocWizard from './SalesDepScreens/SalesCarOfferDocWizard';
import CarSalesOfferPDFCreator from './SalesDepScreens/CarSalesOfferPDfCreator';
import CarsManagementScreenWTabs from './CarScreens/CarsManagement';
import FastEntryScreen from "./CarScreens/QuickDataEntry/FastEntryScreen";
import CarLogsScreen from "../BackOffice/CarLogsScreen";
import ExpansesScreen from "./CarScreens/ExpansesScreen";
// import {"times"} from "@fortawesome/free-solid-svg-icons";
import './customNavbar.css'
import SellBuyReportScreen from "./CarScreens/SellBuyReportScreen";
import PdksControlPanel from "./BackOfficeScreens/PdksControlPanel";
// Amplify.configure(awsconfig);

const HomeScreen = () => {
  const imageLogo = require('../../assets/BilenMobi.png');
  const navigation = useNavigation();
  const [profilePopUp, setprofilePopUpVisible] = useState(false);
  const [currentUser, setAuthUser] = useState("");
  const [attributesC, setAttributes] = useState()
  const [authGroupID, setAuthGroupID] = useState()
  const [choosenCar, setChoosenCar] = useState(undefined);
  const [choosenCars, setChoosenCars] = useState([]);
  const [searchPlaka, setSearchPlaka] = useState("");
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [isKiralama, setIsKiralama] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [isMuhasebe, setIsMuhasebe] = useState(false);

  const [current_screen, setCurrentScreen] = useState(sessionStorage.getItem("HomePageLastSelected") != null ? sessionStorage.getItem("HomePageLastSelected") : "")
  const [expanded, setExpanded] = useState(true)

  const {fetchUserToken, fetchAuthGroup, fetchIdToken, get_curent_user} = useUserToken();
  const {validatePlateNumber, fixPlaka} = useValidationRules();

  useEffect(() => {
    get_curent_user();
    // validateDepartment();
    validateISAdmin();
  }, []);

  useEffect(() => {

    if (isAdmin != undefined && isKiralama != undefined && isSales != undefined) {
      if (isAdmin) {
        setHeaderOptions();
        setCurrentScreen(sessionStorage.getItem("HomePageLastSelected") != null ? sessionStorage.getItem("HomePageLastSelected") : "DASHBOARD");
      } else if (isKiralama) {
        setHeaderOptions();
        setCurrentScreen(sessionStorage.getItem("HomePageLastSelected") != null ? sessionStorage.getItem("HomePageLastSelected") : "DASHBOARD");

      } else if (isMuhasebe) {
        setHeaderOptionsMuhasebe();
        setCurrentScreen(sessionStorage.getItem("HomePageLastSelected") != null ? sessionStorage.getItem("HomePageLastSelected") : "DASHBOARD");
      } else if (isSales) {
        setHeaderOptionsSatış();
        // setCurrentScreen("VISITOR_MANAGEMENT");
      } else {
        setHeaderOptions();
        setCurrentScreen(sessionStorage.getItem("HomePageLastSelected") != null ? sessionStorage.getItem("HomePageLastSelected") : "");
      }
    }
  });

  const onlogout = async () => {
    sessionStorage.clear();
    //  const response = await Auth.signIn(text_name, password);
    const response = await Auth.signOut()
        .then(() => {
              navigation.navigate("SignIn");
              sessionStorage.clear();
              console.log("signed out");
            }
        ).catch(error => {
              console.log('error signing in', error);
              Alert.alert('Error:', error);
            }
        );

  }

  useEffect(() => {
    console.log("PLAKA_DIRECT", sessionStorage.getItem("PLAKA_DIRECT"))
    if (sessionStorage.getItem("PLAKA_DIRECT") != null && searchPlaka == "" && sessionStorage.getItem("CurrentScreen") == "DIRECT_CAR") {
      getCarWithPlaka(sessionStorage.getItem("PLAKA_DIRECT"));
    }
  }, [])


  async function getCarWithPlaka(inputTemp) {
    const api = configData.API_BASE + "/cardata"
    const token = await fetchIdToken();
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    const body = {
      "ENV": configData.APP_ENV,
      "type": "GET_CAR_W_PLAKA",
      "PLAKA": inputTemp != undefined ? inputTemp : searchPlaka
    }

    console.log("body", body)

    console.log("searchPlaka", searchPlaka)
    axios.post(api, body, {headers}).then((response) => {
      console.log(response.data);
      if (response.data.length == 0) {
        setChoosenCar(undefined);
      } else {
        let car = {...response.data[0]};
        car["CAR_ID"] = car["ID"];
        setChoosenCar(car);
        setCurrentScreen("DIRECT_CAR_TEMP");

        sessionStorage.setItem("PLAKA_DIRECT", car["PLAKA"]);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getCarWithKeyword(input) {
    let isFound = false;
    const api = configData.API_BASE + "/cardata"
    const token = await fetchIdToken();
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    const body = {
      "ENV": configData.APP_ENV,
      "type": "GET_WITH_KEYWORD",
      "KEYWORD": input
    }

    console.log("body", body)

    await axios.post(api, body, {headers}).then((response) => {
          console.log(response.data);
          if (response.data.length == 0) {
            setChoosenCars([]);
          } else {
            let cars = [...response.data];
            cars.forEach(element => {
                  element["CAR_ID"] = element["ID"];
                }
            );

            isFound = true;
            setChoosenCars(cars);
            console.log("choosenCars", choosenCars);
            console.log("set SCREEN");
            setCurrentScreen("KEYWORDSEARCH");
          }
        }
    ).catch((error) => {
          console.log(error);
        }
    );
    return isFound;
  }


  // useEffect(() => {
  //   console.log("searchPlaka",searchPlaka)
  //   let result = validatePlateNumber(searchPlaka);
  //   console.log("result",result)
  //   if(result.isValid){
  //     getCarWithPlaka();
  //   }
  //   if(result != null && result != undefined){
  //     // setSearchPlaka(result.plateNumber);
  //     sessionStorage.setItem("PLAKA_DIRECT",result.plateNumber);
  //   }

  // },[searchPlaka])


  async function searchPlakaFunc(input) {
    console.log("searchPlaka", input)
    let result = validatePlateNumber(input);
    console.log("result", result)
    let isFound = false;
    if (result.isValid) {
      await getCarWithPlaka(input);
      isFound = true;
    }
    if (result != null && result != undefined) {
      // setSearchPlaka(result.plateNumber);
      sessionStorage.setItem("PLAKA_DIRECT", result.plateNumber);
    }
    return isFound;
  }

  async function searchWithKeyword(input) {
    if (current_screen == "KEYWORDSEARCH") {
      open("LOADER");
    }
    console.log("searchKeyword", input)
    let isFound = false;
    isFound = await getCarWithKeyword(input);
    console.log("isFound", isFound)
    sessionStorage.setItem("KEYWORDSEARCH", input);

    return isFound;
  }


  useEffect(() => {
    if (current_screen == "DIRECT_CAR_TEMP") {
      console.log("DIRECT_CAR_TEMP")
      open("DIRECT_CAR");
    }
    if (current_screen == "KEYWORDSEARCH") {
      console.log("KEYWORDSEARCH")
      open("KEYWORDSEARCH");
    }
  }, [current_screen]);


  const setHeaderOptions = () => {
    navigation.setOptions({
      header: () => (
          <Navbar expand="lg" className="custom-navbar">
            <Container fluid>
              <Navbar.Brand onClick={() => {
                open("DASHBOARD")
              }} href="#"> <Image source='images/BilenMobi.png' style={{width: 150, height: 40}} resizeMode='contain'/>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${'lg'}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
                  placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                    BMOBI
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>

                  <Nav className="justify-content-start flex-grow-1 pe-3">
                    <Nav.Item>
                      <KeywordSearch search={searchWithKeyword} choosenCars={choosenCars}></KeywordSearch>
                    </Nav.Item>
                    <NavDropdown eventKey={getEventKey()} title='Araçlar' style={styles.navBarHeader}
                                 icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("ARACLAR")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Araç Tablosu </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("SOLD_CAR")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Satılan Araçlar </CustomText></NavDropdown.Item>
                      {/*<NavDropdown.Item onClick={() => {open("FRESH_STOK")}  }  eventKey= {getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"  } /></Icon>}><CustomText header2> Taze Stok Araçlar</CustomText></NavDropdown.Item>*/}
                      <NavDropdown.Item onClick={() => {
                        open("YENI_ARAÇ")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}><CustomText
                          header2> Yeni Araç </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("MULTIPLE_ARAÇ")
                      }} eventKey={getEventKey()}
                                        icon={<Icon><FontAwesomeIcon icon={"square-plus"}/></Icon>}><CustomText
                          header2> Çoklu Araç Giriş </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("MULTI_UPDATE")
                      }} eventKey={getEventKey()}
                                        icon={<Icon><FontAwesomeIcon icon={"pen-to-square"}/></Icon>}><CustomText
                          header2> Çoklu Araç Güncelleme </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("MULTI_FILE_UPLOAD")
                      }} eventKey={getEventKey()}
                                        icon={<Icon><FontAwesomeIcon icon={"file-import"}/></Icon>}><CustomText
                          header2> Çoklu Dosya Yükleme </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("FAST_ENTRY")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}><CustomText
                          header2> Hızlı Araç Veri Girişi </CustomText></NavDropdown.Item>
                    </NavDropdown>
                    <View style={{width: 20, height: 20}}></View>
                    <NavDropdown style={styles.navBarHeader} eventKey={getEventKey()} title={"Bilen CarRental"}
                                 icon={<Icon><FontAwesomeIcon icon={"handshake"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("SÖZLEŞMELER")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}>
                        <CustomText header2>Kira Sözleşmeleri </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("RENT_OFFER_TABLE")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}> <CustomText
                          header2>Kira Teklifleri</CustomText> </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("YENI_SÖZLEŞME")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}><CustomText
                          header2>Yeni Sözleşme</CustomText></NavDropdown.Item>
                      <NavDropdown eventKey={getEventKey()} title={"Kestirmeler"}
                                   icon={<Icon><FontAwesomeIcon icon={"arrow-alt-circle-right"}/></Icon>}>
                        <NavDropdown.Item onClick={() => {
                          open("KASKO_CALC")
                        }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"tag"}/></Icon>}><CustomText
                            header2> Kasko Değer Hesaplama </CustomText></NavDropdown.Item>
                        <LinkCard logo={'images/bcrLogo.png'} input={"https://www.bilen.pro/"}
                                  header={"Bilen Pro"}> </LinkCard>
                        <LinkCard logo={'images/sahibinden_icon.png'} input={"https://bilencarrental.sahibinden.com/"}
                                  header={"Sahibinden"}> </LinkCard>
                        <LinkCard logo={'images/kolaycar_icon.png'} input={"https://v3.kolaycar.com/login.aspx"}
                                  header={"KolayCar"}> </LinkCard>
                        <LinkCard logo={'images/egm_icon.png'} input={"https://arackiralama.egm.gov.tr/"}
                                  header={"EGM"}> </LinkCard>
                        <LinkCard logo={'images/hgs_kurumsal_icon.png'} input={"https://www.hgskurumsal.com/"}
                                  header={"Hgs Kurumsal"}> </LinkCard>
                        <LinkCard logo={'images/logosoft_icon.png'}
                                  input={"https://bilen-tofas.j-platform.com/logo/html"} header={"Logo"}> </LinkCard>
                        <LinkCard logo={'images/gib_icon.png'} input={"https://ivd.gib.gov.tr"}
                                  header={"GİB"}> </LinkCard>
                        <LinkCard logo={'images/ekap_icon.png'} input={"https://ekap.kik.gov.tr/ekap/"}
                                  header={"EKAP"}> </LinkCard>
                        <LinkCard logo={'images/tsb_icon.png'} input={"https://www.tsb.org.tr/tr/kasko-deger-listesi"}
                                  header={"TSB"}> </LinkCard>
                        <LinkCard logo={'images/opet_icon.png'} input={"https://www.opet.com.tr/kurumsal-islemler"}
                                  header={"Opet"}> </LinkCard>
                        <LinkCard logo={'images/shell_icon.png'} input={"https://sfs.turkiyeshell.com/"}
                                  header={"Shell"}> </LinkCard>
                        <LinkCard logo={'images/arvento_icon.png'} input={"https://web.arvento.com/signin.aspx"}
                                  header={"Arvento"}> </LinkCard>
                        <LinkCard logo={'images/turuncupro_icon.png'} input={"https://takip.turuncupro.com/takipEkrani"}
                                  header={"TPro"}> </LinkCard>

                      </NavDropdown>
                    </NavDropdown>

                    <View style={{width: 20, height: 20}}></View>
                    {
                        isAdmin &&
                        <NavDropdown style={styles.navBarHeader} eventKey={getEventKey()} title={"Satış"}
                                     icon={<Icon><FontAwesomeIcon icon={"handshake"}/></Icon>}>
                          <NavDropdown.Item onClick={() => {
                            open("VISITOR_MANAGEMENT")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}> <CustomText
                              header2> Ziyaretçi </CustomText> </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("VISITOR_CAR_MANAGEMENT")
                          }} eventKey={getEventKey()}
                                            icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                              header2> Araçlar </CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("SALES_CARS_OFFERS")
                          }} eventKey={getEventKey()}
                                            icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                              header2> Satış Teklifleri </CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("CAR_DELIVERY_DOC")
                          }} eventKey={getEventKey()}
                                            icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                              header2> Araç Teslim Belgesi </CustomText></NavDropdown.Item>

                        </NavDropdown>
                    }

                    {
                        isAdmin &&
                        <NavDropdown style={styles.navBarHeader} eventKey={getEventKey()} title={"Raporlar"}
                                     icon={<Icon><FontAwesomeIcon icon={"handshake"}/></Icon>}>
                          <NavDropdown.Item onClick={() => {
                            open("EXPANSES")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}><CustomText
                              header2> Masraflar </CustomText></NavDropdown.Item>
                            <NavDropdown.Item onClick={() => {
                              open("SELL_BUY_REPORT")
                            }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}><CustomText
                                header2> Alış Satış Raporu </CustomText></NavDropdown.Item>
                        </NavDropdown>
                    }



                    <NavDropdown style={styles.navBarHeader} eventKey={getEventKey()} title={"Müşteri"}
                                 icon={<Icon><FontAwesomeIcon icon={"people-group"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("KİRALAMA_MÜŞTERİLERİ")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"people-group"}/></Icon>}>
                        <CustomText header2>Müşteriler </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("YENI_MÜŞTERİ")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}><CustomText
                          header2>Yeni Müşteri</CustomText></NavDropdown.Item>
                    </NavDropdown>
                    <View style={{width: 20, height: 20}}></View>
                    {isAdmin &&
                        <NavDropdown eventKey={getEventKey()} title={"Yönetici Paneli"}
                                     icon={<Icon><FontAwesomeIcon icon={"server"}/></Icon>}>
                          <NavDropdown.Item onClick={() => {
                            open("KULLANICI_YÖNETİMİ")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"list"}/></Icon>}> <CustomText>Kullanıcı
                            Yönetimi</CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("BMP_TANIMLAMA")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"list"}/></Icon>}> <CustomText>Marka
                            Model Donanım Tanımlama</CustomText> </NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("KASA_TANIMLAMA")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"list"}/></Icon>}><CustomText>Kasa
                            Tipi Tanımlama</CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("ARAC_PARAMETRE_TANIMLAMA")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}><CustomText>Araç
                            Parametre Tanımlama</CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("LOGS")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon
                              icon={"list"}/></Icon>}><CustomText> Loglar </CustomText></NavDropdown.Item>
                          <NavDropdown.Item onClick={() => {
                            open("PDKS_CONTROL")
                          }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon
                                icon={"list"}/></Icon>}><CustomText> PDKS Kontrol Paneli </CustomText></NavDropdown.Item>

                        </NavDropdown>
                    }


                    <View style={{width: 20, height: 20}}></View>


                    <View style={{flex: 1}}></View>
                    <View style={{width: 20, height: 20}}></View>
                    {/* istek yönetimi  */}
                    <Nav.Item>
                      <IconButton style={{height: 35, width: 200, backgroundColor: '#001489',}}
                                  onClick={() => {
                                    open("REQUESTS")
                                  }}
                                  icon={<Icon style={{height: 35, backgroundColor: '#001489', color: '#F2F2F2'}}>
                                    <FontAwesomeIcon icon={"hand-dots"}></FontAwesomeIcon> </Icon>}>
                        <CustomText fontSize={16} fontWeight={800} style={{color: '#F2F2F2'}}> İstek
                          Yönetimi </CustomText>
                      </IconButton>
                    </Nav.Item>
                    <View style={{width: 20, height: 20}}></View>

                    {/* <Nav.Item>
                            <PlakaSearch search={searchPlakaFunc} choosenCar={choosenCar} ></PlakaSearch>
                          </Nav.Item> */}

                    <IconButton style={{height: 35, width: 120, backgroundColor: '#001489',}}
                                onClick={() => setprofilePopUpVisible(!profilePopUp)}
                                icon={<Icon style={{height: 35, backgroundColor: '#001489', color: '#F2F2F2'}}>
                                  <FontAwesomeIcon icon={"id-badge"}></FontAwesomeIcon> </Icon>}>
                      <CustomText fontSize={16} fontWeight={800} style={{color: '#F2F2F2'}}> Profil </CustomText>
                    </IconButton>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

            </Container>

          </Navbar>
      ),
      // headerRight: () => (

      // ),
    });
  }

  const setHeaderOptionsMuhasebe = () => {
    navigation.setOptions({
      header: () => (
          <Navbar expand="lg" style={{backgroundColor: '#F8F9FA'}}>
            <Container fluid>
              <Navbar.Brand onClick={() => {
                open("DASHBOARD")
              }} href="#"> <Image source={BMLogo} style={{width: 150, height: 40}} resizeMode='contain'/>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${'lg'}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
                  placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                    BMOBI
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-start flex-grow-1 pe-3">


                    <NavDropdown eventKey={getEventKey()} title='Araçlar' style={styles.navBarHeader}
                                 icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("ARACLAR")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"
                      }/></Icon>}><CustomText header2> Araç Tablosu </CustomText></NavDropdown.Item>

                    </NavDropdown>
                    <View style={{width: 20, height: 20}}></View>
                    <NavDropdown style={styles.navBarHeader} eventKey={getEventKey()} title={"Bilen CarRental"}
                                 icon={<Icon><FontAwesomeIcon icon={"handshake"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("SÖZLEŞMELER")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}>
                        <CustomText header2>Kira Sözleşmeleri </CustomText></NavDropdown.Item>
                      {/* <Nav.Item  onSelect={() => {open("RENT_OFFER")} } eventKey= {getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"} /></Icon>}> Kira Teklifi Oluştur </Nav.Item> */}
                      <NavDropdown.Item onClick={() => {
                        open("KİRALAMA_MÜŞTERİLERİ")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"people-group"}/></Icon>}>
                        <CustomText header2>Müşteriler </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("YENI_MÜŞTERİ")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"plus"}/></Icon>}><CustomText
                          header2>Yeni Müşteri</CustomText></NavDropdown.Item>
                    </NavDropdown>

                    <View style={{width: 20, height: 20}}></View>
                    <NavDropdown eventKey={getEventKey()} title='Satış Departmanı' style={styles.navBarHeader}
                                 icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("VISITOR_MANAGEMENT")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Ziyaretçi Yönetimi </CustomText></NavDropdown.Item>
                    </NavDropdown>
                    <View style={{width: 20, height: 20}}></View>


                    <View style={{flex: 1}}></View>
                    <View style={{width: 20, height: 20}}></View>
                    {/* istek yönetimi  */}
                    <Nav.Item>
                      <IconButton style={{height: 35, width: 200, backgroundColor: '#5A6273',}}
                                  onClick={() => {
                                    open("REQUESTS")
                                  }}
                                  icon={<Icon style={{height: 35, backgroundColor: '#5A6273', color: '#F2F2F2'}}>
                                    <FontAwesomeIcon icon={"hand-dots"}></FontAwesomeIcon> </Icon>}>
                        <CustomText fontSize={16} fontWeight={800} style={{color: '#F2F2F2'}}> İstek
                          Yönetimi </CustomText>
                      </IconButton>
                    </Nav.Item>
                    <View style={{width: 20, height: 20}}></View>

                    <Nav.Item>
                      <PlakaSearch search={searchPlakaFunc} choosenCar={choosenCar}></PlakaSearch>
                    </Nav.Item>
                    <View style={{width: 20, height: 20}}></View>

                    <IconButton style={{height: 35, width: 120, backgroundColor: '#248AFD',}}
                                onClick={() => setprofilePopUpVisible(!profilePopUp)}
                                icon={<Icon style={{height: 35, backgroundColor: '#248AFD', color: '#F2F2F2'}}>
                                  <FontAwesomeIcon icon={"id-badge"}></FontAwesomeIcon> </Icon>}>
                      <CustomText fontSize={16} fontWeight={800} style={{color: '#F2F2F2'}}> Profil </CustomText>
                    </IconButton>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>

          </Navbar>
      ),
      // headerRight: () => (

      // ),
    });
  }

  const setHeaderOptionsSatış = () => {
    navigation.setOptions({
      header: () => (
          <Navbar expand="lg" style={{backgroundColor: '#F8F9FA'}}>

            <Container fluid>
              <Navbar.Brand href="#"> <Image source={BMLogo} style={{width: 150, height: 40}} resizeMode='contain'/>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${'lg'}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
                  placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                    BMOBI
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-start flex-grow-1 pe-3">
                    <NavDropdown eventKey={getEventKey()} title='Satış Departmanı' style={styles.navBarHeader}
                                 icon={<Icon><FontAwesomeIcon icon={"car"}/></Icon>}>
                      <NavDropdown.Item onClick={() => {
                        open("VISITOR_MANAGEMENT")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Ziyaretçi Yönetimi </CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("VISITOR_CAR_MANAGEMENT")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Araçlar</CustomText></NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {
                        open("CAR_DELIVERY_DOC")
                      }} eventKey={getEventKey()} icon={<Icon><FontAwesomeIcon icon={"table-list"}/></Icon>}><CustomText
                          header2> Araç Teslim Belgesi </CustomText></NavDropdown.Item>
                    </NavDropdown>
                    <View style={{flex: 1, height: 30}}></View>
                    <IconButton style={{height: 35, width: 120, backgroundColor: '#248AFD',}}
                                onClick={() => setprofilePopUpVisible(!profilePopUp)}
                                icon={<Icon style={{height: 35, backgroundColor: '#248AFD', color: '#F2F2F2'}}>
                                  <FontAwesomeIcon icon={"id-badge"}></FontAwesomeIcon> </Icon>}>
                      <CustomText fontSize={16} fontWeight={800} style={{color: '#F2F2F2'}}> Profil </CustomText>
                    </IconButton>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
      ),
    });
  }

  async function validateISAdmin() {
    const authID = await fetchAuthGroup();
    console.log("authID", authID)
    if (authID == null || authID == undefined) {
      setIsAdmin(false);
      setIsKiralama(false);
      setIsSales(false);
    } else {
      const api = configData.API_BASE + "/userdata"
      let token = await fetchIdToken();
      let ID = -1;
      const headers = {
        'Authorization': `Bearer ${token}`,
      }

      const body = {
        "ENV": configData.APP_ENV,
        "type": "GET_ADMIN_AUTH_GROUP_ID",
      }

      await axios.post(api, body, {headers}).then((response) => {
            console.log("response", response)
            ID = response.data["ID"];
            console.log("ID", ID)
          }
      ).catch(error => {
        console.log(error);
      });
      console.log("ID", ID)
      if (authID.toString() === ID.toString()) {
        sessionStorage.setItem("isAdmin", true);
      }

      setIsAdmin(authID.toString() === ID.toString());
    }

    await validateDepartment();


  }

  async function validateDepartment() {
    const authID = await fetchAuthGroup();
    console.log("authID", authID)
    if (authID == null || authID == undefined) {
      setIsKiralama(false);
      setIsSales(false);
      setIsMuhasebe(false);
    } else {
      const api = configData.API_BASE + "/userdata"
      let token = await fetchIdToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
      }

      const body = {
        "ENV": configData.APP_ENV,
        "type": "GET_AUTH_GROUPS",
      }

      await axios.post(api, body, {headers}).then((response) => {
            console.log("response", response)
            let authGroups = response.data;
            console.log("authGroups", authGroups)
            authGroups.forEach(element => {
              if (element.ID == authID) {
                if (element.AUTH_GROUP_NAME == "KİRALAMA") {
                  setIsKiralama(true);
                }
                if (element.AUTH_GROUP_NAME == "SATIS_DNS") {
                  setIsSales(true);
                }
                if (element.AUTH_GROUP_NAME == "MUHASEBE") {
                  setIsMuhasebe(true);
                }
              }
            });
          }
      ).catch(error => {
        console.log(error);
      });

    }
  }


  // BackOffice children data for left pane header


//#region SCREEN CONTROLLERS


  function open(input) {
    setCurrentScreen(input)
    sessionStorage.setItem("HomePageLastSelected", input);
  }

  //#endregion


  let eventKey = 0;
  const getEventKey = () => {
    eventKey = eventKey + 1;
    return eventKey;
  }


  return (
      <ScrollView contentContainerStyle={{flexGrow: 1}}>
        <SafeAreaView style={{flexDirection: 'column', flex: 1, flexGrow: 1}}>
          <View style={styles.home_view}>
            {/* Profile Popup */}
            <Drawer open={profilePopUp} onClose={() => {
              setprofilePopUpVisible(false)
            }} size='xs' backdrop='static'>
              <Drawer.Body>
                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                  <IconButton style={{height: 35, width: 35, backgroundColor: '#F2F2F2'}} onClick={() => {
                    setprofilePopUpVisible(false)
                  }} icon={<Icon><FontAwesomeIcon icon={"times"}/></Icon>}></IconButton>
                </View>
                <View style={{height: 10}}></View>
                <ProfilePopUpComponent onlogout={onlogout} isNewUser={false} toggleVisible={setprofilePopUpVisible}
                                       isVisible={profilePopUp}></ProfilePopUpComponent>
              </Drawer.Body>
            </Drawer>
            {/* <Drawer overflow={true}  size={'sm'}  backdrop={true} keyboard={false} open={current_screen == 'TEST'} onClose={() => { setCurrentScreen("Dashboard") }} >
            <Drawer.Header>
            <Drawer.Title>

            </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                // component
            </Drawer.Body>
        </Drawer> */}

            <View style={styles.tabs_screen}>

              {(current_screen == 'DASHBOARD') && (<DashboardScreen/>)}
              {(current_screen == 'ARACLAR') && (<CarsManagement/>)}
              {(current_screen == 'SOLD_CAR') && (<CarsManagement sold/>)}
              {(current_screen == 'YENI_ARAÇ') && (<NewCarScreen type="NEW"/>)}
              {(current_screen == 'KULLANICI_YÖNETİMİ') && (<UserManagementScreen/>)}
              {(current_screen == 'MÜŞTERİLER') && (<CustomersScreen/>)}
              {(current_screen == 'BMP_TANIMLAMA') && (<BMPScreen/>)}
              {(current_screen == 'KASA_TANIMLAMA') && (<KasaScreen></KasaScreen>)}
              {(current_screen == 'ARAC_ETIKET_TANIMLAMA') && (<CarTagManagerScreen></CarTagManagerScreen>)}
              {(current_screen == 'ARAC_PARAMETRE_TANIMLAMA') && (<GlobalParametersScreen></GlobalParametersScreen>)}
              {(current_screen == 'YENI_MÜŞTERİ') && (<NewCustomerScreen></NewCustomerScreen>)}
              {(current_screen == 'YENI_SÖZLEŞME') && (<NewRentAggreementScreen></NewRentAggreementScreen>)}
              {(current_screen == 'SÖZLEŞMELER') && (<RentAgreementManagement></RentAgreementManagement>)}
              {(current_screen == 'KİRALAMA_MÜŞTERİLERİ') && (
                  <RentCustomerManagementScreen></RentCustomerManagementScreen>)}
              {(current_screen == 'MULTIPLE_ARAÇ') && (<MultipleCarScreen></MultipleCarScreen>)}
              {(current_screen == 'FRESH_STOK') && (<FreshCarsManagemnt></FreshCarsManagemnt>)}
              {(current_screen == 'MULTI_UPDATE') && (<MultipleCarUpdateScreen></MultipleCarUpdateScreen>)}
              {(current_screen == 'MULTI_FILE_UPLOAD') && (<MultipleFileUploadScreen></MultipleFileUploadScreen>)}
              {(current_screen == 'KASKO_CALC') && (<KaskoValueCalculator></KaskoValueCalculator>)}
              {(current_screen == 'RENT_OFFER') && (<RentContractTemplate></RentContractTemplate>)}
              {(current_screen == 'RENT_OFFER_TABLE') && (<RentContractOffers></RentContractOffers>)}
              {(current_screen == 'VISITOR_MANAGEMENT') && (<SalesVisitorsScreen></SalesVisitorsScreen>)}
              {(current_screen == 'VISITOR_CAR_MANAGEMENT') && (<SalesCarsOffers></SalesCarsOffers>)}
              {(current_screen == 'SALES_CARS_OFFERS') && (<CarSalesOfferPDFCreator></CarSalesOfferPDFCreator>)}
              {(current_screen == 'CAR_DELIVERY_DOC') && (<CarDeliveryDocument></CarDeliveryDocument>)}
              {(current_screen == 'REQUESTS') && (<Requests></Requests>)}
              {(current_screen == 'FAST_ENTRY') && (<FastEntryScreen></FastEntryScreen>)}
              {(current_screen == 'EXPANSES') && (<ExpansesScreen></ExpansesScreen>)}
              {(current_screen == 'PDKS_CONTROL') && (<PdksControlPanel></PdksControlPanel> )}
              {(current_screen == 'SELL_BUY_REPORT') && (<SellBuyReportScreen></SellBuyReportScreen>)}
              {(current_screen == 'DIRECT_CAR' && (choosenCar != undefined ? (<CarInfoScreen reset={() => {
                    console.log("reset")
                  }} getCars={choosenCar} data={choosenCar}></CarInfoScreen>) :
                  (<CustomText style={{
                    textAlign: 'center',
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: '#B4B4B4'
                  }}> Araç Bulunamadı </CustomText>)))}
              {(current_screen == 'KEYWORDSEARCH' && (choosenCars != undefined ? (
                      <CarsManagement givenData={choosenCars}></CarsManagement>) :
                  (<CustomText style={{
                    textAlign: 'center',
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: '#B4B4B4'
                  }}> Araç Bulunamadı </CustomText>)))}
              {(current_screen == 'LOADER') && (<LoaderScreen></LoaderScreen>)}
              {(current_screen == 'LOGS') && (<CarLogsScreen></CarLogsScreen>)}


            </View>
          </View>
        </SafeAreaView>
      </ScrollView>
  );

}


const LinkCard = (props) => {
  return (
      <View style={{
        flexDirection: 'row',
        height: '95%',
        flex: 1,
        backgroundColor: 'white',
        marginVertical: 5,
        borderRadius: 7
      }}>

        {props.logo != undefined &&
            <Button
                onClick={() => {
                  window.open(props.input, "_blank")
                }}
                style={{alignItems: 'center', flex: 1, backgroundColor: 'white'}}>
              <Image style={{width: 30, height: 30}} resizeMode='contain' source={props.logo}/>
            </Button>
        }
        <Button
            onClick={() => {
              window.open(props.input, "_blank")
            }}
            style={{flex: 2, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center'}}>
          <CustomText style={{fontSize: 12, fontWeight: 'bold', color: 'B4B4B4'}}>
            {props.header}
          </CustomText>
        </Button>
      </View>
  )
}

const PlakaSearch = (props) => {
  const [plateNumber, setPlateNumber] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [carFound, setCarFound] = useState(false);
  const {messageToggle} = useMessage()

  const {fixPlaka} = useValidationRules();

  async function search() {
    console.log("plateNumber", plateNumber)
    let found = await props.search(fixPlaka(plateNumber));
    console.log("found", found)
    if (found) {
      setSearchClicked(false);
      messageToggle("Araç Bulundu", "success");
    } else {
      setSearchClicked(false);
      messageToggle("Araç Bulunamadı", "error");
    }
  }

  useEffect(() => {
    console.log("props.choosenCar TRIGGERED" + plateNumber)
    if (plateNumber != "") {
      setSearchClicked(true);
    }
  }, [])

  return (
      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 250}}>
        <InputGroup style={{width: 250}}>
          <InputGroup.Addon style={{
            backgroundColor: '#F2F2F2',
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          }}>
            {searchClicked ?
                <ActivityIndicator style={{height: 30}}></ActivityIndicator>
                :
                <IconButton style={{height: 35, backgroundColor: '#F2F2F2'}} size='xs' onClick={() => {
                  search();
                  setSearchClicked(true)
                }} icon={<Icon><FontAwesomeIcon icon={"search"}/></Icon>}></IconButton>

            }
          </InputGroup.Addon>
          <Input onPressEnter={() => {
            search();
            setSearchClicked(true)
          }} placeholder='Plaka Ara' style={{height: 35, fontFamily: 'Barlow', fontSize: 18}} value={plateNumber}
                 onChange={(value) => {
                   setPlateNumber(fixPlaka(value))
                 }}/>
        </InputGroup>

      </View>
  )
}

// will possibly be get multiple listable cars with keyword
const KeywordSearch = (props) => {
  const [keyword, setKeyword] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [carFound, setCarFound] = useState(false);
  const {messageToggle} = useMessage()

  async function search() {
    console.log("keyword", keyword)
    let found = await props.search(keyword);
    console.log("found", found)
    if (found) {
      setSearchClicked(false);
      messageToggle("Araç Bulundu", "success");
    } else {
      setSearchClicked(false);
      messageToggle("Araç Bulunamadı", "error");
    }
  }

  useEffect(() => {
    console.log("props.choosenCar TRIGGERED" + keyword)
    if (keyword != "") {
      setSearchClicked(true);
    }
  }, [])
  return (

      <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 250}}>
        <InputGroup style={{width: 250}}>
          <InputGroup.Addon style={{
            backgroundColor: '#F2F2F2',
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          }}>
            {searchClicked ?
                <ActivityIndicator style={{height: 30}}></ActivityIndicator>
                :
                <IconButton style={{height: 35, backgroundColor: '#F2F2F2'}} size='xs' onClick={() => {
                  search();
                  setSearchClicked(true)
                }} icon={<Icon><FontAwesomeIcon icon={"search"}/></Icon>}></IconButton>

            }
          </InputGroup.Addon>
          <Input onPressEnter={() => {
            search();
            setSearchClicked(true)
          }} placeholder='Anahtar Kelime Ara' style={{height: 35, fontFamily: 'Barlow', fontSize: 18}} value={keyword}
                 onChange={(value) => {
                   setKeyword(value)
                 }}/>
        </InputGroup>

      </View>
  )
}


const LoaderScreen = () => {
  return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator size="large"></ActivityIndicator>
      </View>
  )
}

const styles = StyleSheet.create({

  default_button: {
    backgroundColor: '#00589B',
    borderRadius: "12px",
    margin: "auto",
    marginTop: "2rem",
    width: 100,
    height: 25
  },
  navBarHeader: {fontFamily: 'Barlow', fontSize: 18},
  button_Text: {
    color: "#f7f3f2",
    textAlign: "center",
    margin: 'auto',
  },
  home_view: {
    //height: '100%',
    flexGrow: 1,
    flex: 5,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
  },
  left_panel: {
    flexDirection: 'column',
    width: 240,
    backgroundColor: '#00589B',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start'
  },
  tabs_screen: {
    flex: 1,
    height: '100%',
    backgroundColor: '#F2F2F2',
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  }

});

export default HomeScreen;

