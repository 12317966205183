import React, { useState, useEffect } from "react";
import {
    Table,
    Loader,
    Panel,
    Container,
    Header,
    Content,
    Input,
    Button,
    Checkbox,
    SelectPicker,  // SelectPicker ekleniyor
    Timeline,
} from "rsuite";
import axios from "axios";
import CustomText from "../../Components/CustomText";
import { View, StyleSheet } from "react-native";

const { Column, HeaderCell, Cell } = Table;
const API_URL =
    "https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/prod/pdks-checkin";

const PdksControlPanel = () => {
    const [attendanceData, setAttendanceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [announcements, setAnnouncements] = useState([]);
    const [announcementHeader, setAnnouncementHeader] = useState("");
    const [announcementBody, setAnnouncementBody] = useState("");
    const [userAttendance, setUserAttendance] = useState({});
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [isActiveAnnouncement, setIsActiveAnnouncement] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null); // Seçili kullanıcı durumu

    useEffect(() => {
        fetchAttendance();
        fetchAnnouncements();
    }, []);

    const fetchAttendance = async () => {
        try {
            const response = await axios.post(API_URL, { type: "get-attandance" });
            const data = JSON.parse(response.data.body);
            setAttendanceData(data);
            processUserAttendance(data);
        } catch (error) {
            console.error("Katılım verileri alınamadı:", error);
        } finally {
            setLoading(false);
        }
    };

    const processUserAttendance = (data) => {
        const groupedData = data.reduce((acc, entry) => {
            if (!acc[entry.email]) {
                acc[entry.email] = [];
            }
            acc[entry.email].push(entry);
            return acc;
        }, {});
        setUserAttendance(groupedData);
    };

    const fetchAnnouncements = async () => {
        try {
            const response = await axios.post(API_URL, {
                type: "get-announcements",
                isMobile: "0",
            });
            console.log(response);
            const data = JSON.parse(response.data.body);
            if (Array.isArray(data)) {
                setAnnouncements(data);
            } else {
                console.error("Beklenmeyen API yanıt formatı", data);
                setAnnouncements([]);
            }
        } catch (error) {
            console.error("Duyurular alınamadı:", error);
            setAnnouncements([]);
        }
    };

    const newAnnouncement = async () => {
        if (!announcementHeader || !announcementBody) return;
        try {
            await axios.post(API_URL, {
                type: "new-announcement",
                announcement: {
                    HEADER: announcementHeader,
                    BODY: announcementBody,
                    USER: "1",
                    DATE: new Date().toISOString(),
                },
            });
            setAnnouncementHeader("");
            setAnnouncementBody("");
            setIsActiveAnnouncement(true);
            fetchAnnouncements();
        } catch (error) {
            console.error("Yeni duyuru eklenemedi:", error);
        }
    };

    const handleSaveAnnouncement = async () => {
        if (!announcementHeader || !announcementBody) return;

        let response;
        console.log(isActiveAnnouncement);

        try {
            if (editingAnnouncement) {
                // Duyuru güncelle
                response = await axios.post(API_URL, {
                    type: "update-announcement",
                    ID: editingAnnouncement.ID,
                    HEADER: announcementHeader,
                    BODY: announcementBody,
                    CREATED_USER: "1",
                    CREATE_DATE: new Date().toISOString(),
                    IS_ACTIVE: isActiveAnnouncement ? 1 : 0,
                });
            } else {
                // Yeni duyuru oluştur
                response = await axios.post(API_URL, {
                    type: "new-announcement",
                    HEADER: announcementHeader,
                    BODY: announcementBody,
                    CREATED_USER: "1",
                    CREATE_DATE: new Date().toISOString(),
                    IS_ACTIVE: isActiveAnnouncement ? 1 : 0,
                });
            }
        } catch (error) {
            console.error(
                editingAnnouncement
                    ? "Duyuru güncellenemedi:"
                    : "Yeni duyuru oluşturulamadı:",
                error
            );
            return;
        }

        console.log(response);
        if (response.data.statusCode === 200) {
            setAnnouncementHeader("");
            setAnnouncementBody("");
            setEditingAnnouncement(null);
            fetchAnnouncements();
        }
    };

    const handleEditAnnouncement = (announcement) => {
        setEditingAnnouncement(announcement);
        setAnnouncementHeader(announcement.HEADER);
        setAnnouncementBody(announcement.BODY);
        setIsActiveAnnouncement(announcement.IS_ACTIVE === 1);
    };

    // Kullanıcı seçenekleri: userAttendance objesinin anahtarlarından oluşturuluyor
    const userOptions = Object.keys(userAttendance).map((email) => ({
        label: email,
        value: email,
    }));

    return (
        <Container style={styles.container}>
            <Header>
                <CustomText style={styles.title}>PDKS Control Panel</CustomText>
            </Header>
            <Content>
                <Panel bordered header="Announcements" style={styles.panel}>
                    {loading ? (
                        <Loader backdrop content="Loading..." vertical />
                    ) : (
                        <View style={styles.announcementsContainer}>
                            {announcements.map((announcement) => (
                                <View
                                    key={announcement.ID}
                                    style={styles.announcementCard}
                                >
                                    <View
                                        style={[
                                            styles.colorIndicator,
                                            { backgroundColor: "#ff5a5f" },
                                        ]}
                                    />
                                    <View style={styles.announcementContent}>
                                        <CustomText>{announcement.HEADER}</CustomText>
                                        <CustomText>{announcement.BODY}</CustomText>
                                        <CustomText>
                                            {new Date(announcement.CREATE_DATE).toLocaleString()}
                                        </CustomText>
                                        <CustomText>
                                            Created by: {announcement.CREATED_USER}
                                        </CustomText>
                                        <CustomText>
                                            {announcement.IS_ACTIVE === 1 ? "Active" : "Inactive"}
                                        </CustomText>
                                    </View>
                                    <Button
                                        appearance="link"
                                        onClick={() => handleEditAnnouncement(announcement)}
                                    >
                                        Edit
                                    </Button>
                                </View>
                            ))}
                        </View>
                    )}
                </Panel>

                <Panel
                    bordered
                    header={editingAnnouncement ? "Edit Announcement" : "New Announcement"}
                    style={styles.panel}
                >
                    <Input
                        style={styles.input}
                        placeholder="Başlık"
                        value={announcementHeader}
                        onChange={(value) => setAnnouncementHeader(value)}
                    />
                    <Input
                        style={styles.input}
                        placeholder="İçerik"
                        componentClass="textarea"
                        rows={3}
                        value={announcementBody}
                        onChange={(value) => setAnnouncementBody(value)}
                    />
                    <Checkbox
                        checked={isActiveAnnouncement}
                        onChange={(value, checked) => {
                            setIsActiveAnnouncement(checked);
                            console.log(checked);
                        }}
                    >
                        Aktif
                    </Checkbox>

                    <Button
                        appearance="primary"
                        onClick={handleSaveAnnouncement}
                        style={styles.button}
                    >
                        {editingAnnouncement ? "Update Announcement" : "Duyuru Yap"}
                    </Button>
                </Panel>

                <Panel bordered header="Employee Attendance" style={styles.panel}>
                    {loading ? (
                        <Loader backdrop content="Loading..." vertical />
                    ) : (
                        <Table data={attendanceData} autoHeight>
                            <Column flexGrow={1}>
                                <HeaderCell>Date</HeaderCell>
                                <Cell dataKey="timestamp" />
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Employee Name</HeaderCell>
                                <Cell dataKey="email" />
                            </Column>
                            <Column flexGrow={1}>
                                <HeaderCell>Tip</HeaderCell>
                                <Cell dataKey="eventType" />
                            </Column>
                        </Table>
                    )}
                </Panel>

                <Panel bordered header="Attendance Report" style={styles.panel}>
                    {Object.keys(userAttendance).map((user) => (
                        <Panel key={user} bordered header={user} style={styles.userPanel}>
                            <Timeline>
                                {userAttendance[user].map((entry, index) => (
                                    <Timeline.Item
                                        key={index}
                                        color={
                                            entry.eventType === "log-checkin" ? "green" : "red"
                                        }
                                    >
                                        {entry.eventType} -{" "}
                                        {new Date(entry.timestamp).toLocaleString()}
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    ))}
                </Panel>

                {/* Yeni SelectPicker ile kullanıcı seçimi ve timeline gösterimi */}
                <Panel bordered header="Kullanıcıya Göre Katılım Takvimi" style={styles.panel}>
                    <SelectPicker
                        data={userOptions}
                        placeholder="Kullanıcı Seçin"
                        style={{ width: 224, marginBottom: 20 }}
                        onChange={(value) => setSelectedUser(value)}
                        value={selectedUser}
                    />
                    {selectedUser && userAttendance[selectedUser] && (
                        <Timeline>
                            {userAttendance[selectedUser].map((entry, index) => (
                                <Timeline.Item
                                    key={index}
                                    color={
                                        entry.eventType === "log-checkin" ? "green" : "red"
                                    }
                                >
                                    {entry.eventType} -{" "}
                                    {new Date(entry.timestamp).toLocaleString()}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    )}
                </Panel>
            </Content>
        </Container>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: "#f4f4f4",
        minHeight: "100vh",
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 20,
    },
    panel: {
        marginBottom: 20,
        padding: 20,
        backgroundColor: "#fff",
        borderRadius: 8,
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    },
    userPanel: {
        marginBottom: 10,
    },
    input: {
        width: "100%",
        marginBottom: 10,
        padding: 8,
    },
    button: {
        width: "100%",
        padding: 10,
        backgroundColor: "#ff5a5f",
        color: "#fff",
        borderRadius: 5,
        textAlign: "center",
    },
    announcementsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    announcementCard: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
        padding: 10,
        backgroundColor: "#f9f9f9",
        borderRadius: 5,
    },
    colorIndicator: {
        width: 10,
        height: "100%",
        borderRadius: 5,
        marginRight: 10,
    },
    announcementContent: {
        flex: 1,
    },
});

export default PdksControlPanel;
