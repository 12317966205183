import { Text, View } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useToaster, Message,IconButton,SelectPicker, RadioGroup, Radio} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Icon } from '@rsuite/icons';
import axios from 'axios';
import ErrorLabelInputText from '../../Components/InputTextWithErrors';
import PasswordInputText from '../../Components/PasswordInput';
import configdata from "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
const NewUserPanel = (props) => {

  const toaster = useToaster();
  function messageToggle(input,type){
      toaster.push(
          <Message showIcon type={type}>
            {input}
          </Message>, { placement: 'topEnd' })
  }
    const [name,setName] = useState("");
    const [surname,setSurname] = useState("");
    const [email,setEmail] = useState("");
    const [tempPassword,setTempPassword]= useState("");
    const [canPost, setCanpost]= useState(false);
    const [PasswordCheck, setPasswordCheck] = useState(false);
    const [nameCheck, setnameCheck] = useState(false);
    const [emailCheck, setemailCheck] = useState(false);
    const [surnameCheck, setsurnameCheck ]= useState(false);
    const [AuthGroupID, setAuthGroupID] = useState(1);
    const [authGroups, setAuthGroups] = useState([]);
    const [isLoginable, setIsLoginable] = useState("1");

    const getAuthGroups = async () => {
      const api = configdata.API_BASE + '/uidropdownlist';
      axios.post(api,{
        "ENV" :configdata.APP_ENV ,
        "category": "AUTH_GROUPS",
        "hasParent": "NO",
        "type" : "GET",
      })
      .then((response) => {
        console.log(response);
        let temp = [];
        response.data.forEach(element => {
          temp.push({label: element.AUTH_GROUP_NAME, value: element.ID});
        });
        setAuthGroups(temp);
      })
      .catch((error) => {
        console.log("POST ERROR" + error);

      });
    }

    useEffect(() => {
      getAuthGroups();

    }, []);


    const {fetchIdToken} = useUserToken();

    function checkInputs(){

      if(name != "" && name.length > 1){
        // console.log("name check");
        setnameCheck(true);
      }else{
        setnameCheck(false);
      }

      if(surname != "" && surname.length > 1){
        // console.log("surname check");
        setsurnameCheck(true);
      }else
      {
        setsurnameCheck(false);
      }

      if(email != "" && email.includes("@")){
        // console.log("email check");
        setemailCheck(true);
      }else{
        setemailCheck(false);
      }
      if(tempPassword.length >= 8) {
        // console.log("password check");
        setPasswordCheck(true);
      }else{
        setPasswordCheck(false);
      }

    }

    function checkNameSurnameEmail(){
      console.log(props.data);
      let condition = true;
      props.data.forEach(element => {
        if(element.NAME == name && element.SURNAME == surname && element.EMAIL == email){
          console.log("name surname email check -----------------------");
          console.log(element.NAME);
          console.log(element.SURNAME);
          console.log(element.EMAIL);
          condition = false;
          messageToggle("User Already Exists","error");
        }
      });

      if(condition){
        console.log("user not exists on the table");
      }
     return condition;
    }
    function crate_and_save(){
      let cond = checkNameSurnameEmail();

      if(cond && isLoginable == "0"){
        saveUser();
      }
      if(cond && isLoginable == "1" && PasswordCheck){
        saveUser();
      }
    }
    async function saveUser() {
      const api = configdata.API_BASE + '/userdata';
      let token = await fetchIdToken();
      const headers = {
        "Authorization" : `Bearer ${token}`,
      }
      const header = {
          "NAME": name,
          "SURNAME":surname,
          "EMAIL": email,
          "IS_NEW" : "YES",
          "type" : "USER_ADD_UPDATE",
          "ENV" :configdata.APP_ENV,
          "AuthGroupID" : AuthGroupID,
          "tempPassword" : tempPassword,
          "ADD_TYPE" :  isLoginable == "1" ? "LOGINABLE" : "NOT_LOGINABLE",

      }

      axios
      .post(api,header,{headers})
      .then((response) => {
        console.log(response);
        if(response.data == "SUCCESS"){
          messageToggle("User Created","success");
          props.toggleNewUser();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }

    return(
      <View style= {{width: "100%", height : "100%", justifyContent: 'center', alignItems: 'center'}}>
          <View style= {{flexDirection: 'column',width: "60%", height : "90%"}}>
          <RadioGroup name="radioList" inline appearance="picker" defaultValue="1" onChange={setIsLoginable}>
            <Radio value="1"> Giriş Yapabilir Kullanıcı </Radio>
            <Radio value="0"> Giriş Yapmayacak Kullanıcı </Radio>
          </RadioGroup>
          <ErrorLabelInputText
          type = "name"
          showError = {!nameCheck}
          errorText = "Name Can Not Be Null"
          placeholder='Name'
          onChange={setName}
          checkError= {checkInputs}
          />
          <ErrorLabelInputText
          type = "surname"
          showError = {!surnameCheck}
          errorText = "Surname Can Not Be Null"
          placeholder='Surname'
          onChange={setSurname}
          checkError= {checkInputs}
          />
          <ErrorLabelInputText
              type = "email"
              showError = {!emailCheck}
              errorText = "Invalid Email"
              placeholder='Email'
              onChange={setEmail}
              checkError= {checkInputs}
          />
          {
            isLoginable  == "1" &&
            <View>

              <PasswordInputText
              setText = {setTempPassword}
              placeholder = {"Temporary Password"}
              showError = {!PasswordCheck}
              checkError = {checkInputs}
              errorText = "Password length should be greater or equal to 8"
              />
            </View>
          }


           <View style={{flexDirection: "row", padding: 10,alignItems: 'center'}}>
            <Text style={{marginHorizontal: 10, color: 'powderblue' , fontWeight: 'bold' , fontSize: 16}} > Yetki Tipi </Text>
            <View style={{width: 10}}></View>
            <SelectPicker style={{width: 150}} data = {authGroups} onChange={setAuthGroupID} value={AuthGroupID} placeholder={"Yetki Seç"} >  </SelectPicker>
          </View>
           <View style = {{flexDirection: "row", padding: 10, justifyContent:"flex-end"}}>
            <IconButton
                onClick={crate_and_save}
                icon={<Icon><FontAwesomeIcon icon = {'save'}></FontAwesomeIcon></Icon>}> Kaydet </IconButton>
            <View style={{width: 25}}></View>
            <IconButton
                onClick={props.toggleNewUser}
                style={{backgroundColor: "#F51862", color: "white"}}
                icon={<Icon style={{backgroundColor: "#F51862"}} ><FontAwesomeIcon icon = {'cancel'}
                color='white'
                ></FontAwesomeIcon></Icon>}> İptal </IconButton>
          </View>


        </View>
      </View>

    )

  }

  export default NewUserPanel;
